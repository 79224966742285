<template>
  <div>
    <el-form-item label="Fournisseur" prop="contact_id">
      <el-select
        class="w-full"
        filterable
        clearable
        v-model="customerInfo.contact_id"
        remote
        reserve-keyword
        :remote-method="searchSupplier"
        :loading="loading"
        placeholder="Rechercher un fournisseur"
        @change="handleSupplier"
      >
        <el-option
          v-for="item in suppliers"
          class="uppercase"
          :key="item.contact_id"
          :label="`${item.contact_no} - ${item.contact_name}`"
          :value="item.contact_id"
        >
          <div class="uppercase">
            <span style="float: left">{{ item.contact_name }}</span>

            <span style="float: right">
              {{ item.phone }} / {{ item.street }}
            </span>
          </div>
        </el-option>
      </el-select>
    </el-form-item>

    <div v-loading="loadingCustomerInfo">
      <div class="flex flex-col bg-gray-100 mt-5 w-2/3 p-3">
        <div class="flex justify-between mb-2">
          <span class="text-gray-500">N°</span>
          <span>{{ customerInfo.contact_no }}</span>
        </div>
        <div class="flex justify-between mb-2">
          <span class="text-gray-500">Téléphone</span>
          <span>{{ customerInfo.phone }}</span>
        </div>
        <div class="flex justify-between mb-2">
          <span class="text-gray-500">Email</span>
          <span>{{ customerInfo.email }}</span>
        </div>
        <div class="flex justify-between">
          <span class="text-gray-500">Adresse</span>
          <span>{{ customerInfo.street }}</span>
        </div>
        <div class="flex justify-between">
          <span class="text-gray-500">Correspondant</span>
          <span
            >{{ customerInfo.last_name }} {{ customerInfo.first_name }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchSupplier } from "@/api/contact";

import { toThousandFilter } from "@/Filters";
export default {
  name: "SEARCH-SUPPLIER",
  filters: {
    moneyFilter: toThousandFilter,
  },
  props: {
    customerInfo: {
      type: Object,
      required: true,
    },
    suppliers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      //  customers: [],
      customerSelect: "",
      loadingCustomerInfo: false,

      loading: false,
    };
  },
  mounted() {},

  methods: {
    async searchSupplier(query) {
      if (query.length !== "") {
        this.loadingCustomer = true;
        await searchSupplier({ search: query })
          .then((res) => {
            setTimeout(() => {
              this.$emit("search-supplier", res.data);
              this.loadingCustomer = false;
            }, 200);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleSupplier(id) {
      this.loadingCustomerInfo = true;
      setTimeout(() => {
        this.loadingCustomerInfo = false;
        this.$emit("supplier", id);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.customer-balance {
  background: $tertiary;
  width: 300px;

  padding: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .balance {
    font-weight: bold;
    color: $green-dark;
    font-size: 18px;
  }
  .label {
  }
}
</style>
