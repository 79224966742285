import request from "@/utils/request";

export function nextPurchaseOrder() {
  return request({
    url: "/bills/next-purchase-number",
    method: "get",
  });
}

export function converPurchaseOrderToBill(data) {
  return request({
    url: "/bills/conver-to-bill",
    method: "post",
    data,
  });
}

// create bill
export function createBill(data) {
  return request({
    url: "/bills",
    method: "post",
    data,
  });
}

export function updateBill(data,id) {
  return request({
    url: `/bills/${id}`,
    method: "put",
    data,
  });
}

export function copyBill(id,type) {
  return request({
    url: `/bills/copy/${type}/${id}`,
    method: "put",
   
  });
}

export function opendBill(id,type) {
  return request({
    url: `/bills/conver-to-opend/${type}/${id}`,
    method: "put",
   
  });
}

export function cancelBill(id,type) {
  return request({
    url: `/bills/cancel/${type}/${id}`,
    method: "delete",
   
  });
}

export function getBillByID(id,type) {
  return request({
    url: `/bills/${type}/${id}`,
    method: "get",
  });
}

export function fetchStats(type) {
  return request({
    url: `/bills/stats-open/${type}`,
    method: "get",
  });
}

export function getAllBill(query) {
  return request({
    url: `/bills/fetch/async`,
    method: "get",
    params: query,
  });
}

export function fetchStatSupplier(id) {
  return request({
    url: `/bills/supplier/stats/${id}`,
    method: "get",
  });
}

export function getAllBillOrder(code,type) {
  return request({
    url: `/bills/order/${type}/${code}`,
    method: "get",
  });
}
export function getBalanceVendor(id) {
  return request({
    url: `/bills/balance/${id}`,
    method: "get",
  });
}

export function getTotalBill(query) {
  return request({
    url: `/bills/total`,
    method: "get",
    params: query,
  });
}

export function getAllBillContact(ID,type) {
  return request({
    url: `/bills/contact/${type}/${ID}`,
    method: "get",
  });
}

// paiement bill
export function generatePaymentNo() {
  return request({
    url: "/payment-bill/next-paymentno",
    method: "get",
  });
}

export function createPaymentSingleBill(data) {
  return request({
    url: "/payment-bill/single",
    method: "post",
    data,
  });
}

export function getAllPaymentBill(query) {
  return request({
    url: `/payment-bill/fetch/async`,
    method: "get",
    params: query,
  });
}
export function getItemsPayment(id) {
  return request({
    url: `/payment-bill/items/${id}`,
    method: "get",
  });
}

export function getItemsPaymentForBill(id,query) {
  return request({
    url: `/payment-bill/bill/${id}`,
    method: "get",
    params : query
  });
}

export function cancelPaymentBill(id) {
  return request({
    url: `/payment-bill/cancel/${id}`,
    method: "delete",
  });
}

export function fetchStatPurchase() {
  return request({
    url: `/rapport-purchase/stat`,
    method: "get",
  });
}

// rapport bill
export function fetchRapportBillArticle(query) {
  return request({
    url: `/bills/rapport/article`,
    method: "get",
    params: query,
  });
}

export function fetchRapportBillSupplier(query) {
  return request({
    url: `/bills/rapport/suppliers`,
    method: "get",
    params: query,
  });
}

// export PDF
export function exportBillsExcel(query) {
  return request({
    url: `/bills/export/excel`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}

export function  exportBillsPDF(query) {
  return request({
    url: `/bills/export/pdf`,
    method: "get",
    params: query,
    responseType: "blob",
  });
}